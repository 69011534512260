import React, { useState } from 'react'
import { HeadFC, PageProps } from 'gatsby'

// Models
import { GenreModel } from '../../../models'

// graphQL
import { getGraphComicsCards } from '../../../graphQL'

// Components
import PageTemplate from '../PageTemplate'
import DefaultHelmet from '../../atoms/DefaultHelmet'
import ComicCard from '../../../components/atoms/ComicCard'

// Style
import { genreTitle, genreWrapper, genreDescription } from './style.module.scss'

const GenreTemplate: React.FC<PageProps<any, GenreModel>> = ({
  pageContext: { id, name, description },
}) => {
  const allComics = getGraphComicsCards().filter((comic) =>
    comic.genres.some((g) => g.name === name)
  )

  const [sorted, setSorted] = useState(
    allComics.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  )

  return (
    <PageTemplate>
      <h1 className={genreTitle}>{name}</h1>
      <h3 className={genreDescription}>{description}</h3>
      <div className={genreWrapper}>
        {sorted.map((c) => (
          <ComicCard comic={c} exclude={id} key={c.id} />
        ))}
      </div>
    </PageTemplate>
  )
}

export const Head: HeadFC<any, GenreModel> = ({
  pageContext: { name, description },
}) => (
  <>
    <title>{name} - Comic Colombiano</title>
    <meta
      name="description"
      content={
        description ??
        '¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!'
      }
    />
    <meta
      name="keywords"
      content={[
        description.split(' ').join(','),
        'colombiano,autor,base,de,datos,ilustrador,escritor,diseñador,novela,grafica,arte,revistillas,catalogo',
      ].join(',')}
    />
    <DefaultHelmet />
  </>
)

export default GenreTemplate
